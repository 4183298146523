import React,{useEffect,useRef,useState} from 'react';
import './App.css';

export default function App() {
  const container = useRef(null)
  const pauser = useRef(null)
  const position = useRef(0)
  const wpm = useRef(0)

  const [words, setWords] = useState([])
  const [files, setFiles] = useState('')
  const [flowSpeed, setFlowSpeed] = useState(250)
  const [paused, setPaused] = useState(true)
  const [repeater, setRepeater] = useState(0)
  const [wordsPerMinute, setWordsPerMinute] = useState(250)
  const [globalIndex, setGlobalIndex] = useState(0)

  function setup_repeater() {
    if (words && words.length) {
      clearInterval(repeater)

      let I = +globalIndex || 0

      setRepeater(setInterval(function(){
        let word = words[I]

        if (word) container.current.innerHTML = word.trim()

        I += 1
        setGlobalIndex(I)
      }, flowSpeed))

      wpm.current.innerHTML = `${wordsPerMinute}wpm`
    }
  }

  function handleFileSelect(event) {
      const f = event.target.files[0]
      const reader = new FileReader()

      setFiles(f)

      reader.onload = (function(reader) {
        load_spot()

        return function() {
          let contents = reader.result
          setWords(contents.split(/\s+/))
          console.log('new words')
        }
      })(reader);

      reader.readAsText(f);
  }
  
  function change_speed(delta) {
    setWordsPerMinute(wordsPerMinute+delta)
    setFlowSpeed(parseInt(60000 / wordsPerMinute))
    console.log(`${wordsPerMinute}wpm`, flowSpeed)
    if (!paused) setup_repeater(repeater, words)
  }

  const decreaseSpeed = event=>change_speed(-5)
  const increaseSpeed = event=>change_speed(5)

  function load_spot() {
    let name = files
    let position = localStorage[name] || 0
    setGlobalIndex(+position)
  }

  function pause() {
    console.log('pausing')
    clearInterval(repeater)
    setPaused(true)
    saveSpot()

    const ctrl = document.querySelectorAll('#controls :not(#pauser)')
    ctrl.forEach(el=>el.classList.remove('dim'))
  }

  function unpause() {
    if (words && words.length) {
      change_speed(0)
      setPaused(false)
      
      const ctrl = document.querySelectorAll('#controls :not(#pauser)')
      ctrl.forEach(el=>el.classList.add('dim'))
    }
  }

  function saveSpot() {
    pause()
    let name = files
    localStorage[name] = +globalIndex
  }
  
  function setPosition(event) {
    pause()
    setGlobalIndex(position.current.value)
  }

  function togglePause() {
    if (paused) unpause()
    else pause()
  }


  useEffect(() => {
    if (!paused) setup_repeater()
  }, [paused,words])

  useEffect(() => {
    pauser.current.textContent = paused ? 'Play' : 'Pause'

    if (paused) localStorage[files] = +globalIndex
  }, [paused])

  useEffect(() => {
    setFiles(localStorage.files)
  }, [])


  return (
    <div>
      <div id="main">
        <div id="word" ref={container}></div>
        <div id="dot">&nbsp;</div>
      </div>

      <div id="controls">
        <hr/>
        <h3 ref={wpm}></h3>

        <div>
          <button onClick={decreaseSpeed}>Slower</button>
          <button
            id="pauser"
            onClick={togglePause}
            ref={pauser}
            type="button"
          >Pause</button>
          <button onClick={increaseSpeed}>Faster</button>
        </div>

        <h3>{globalIndex}/{words.length}</h3>

        <input
          id="progress"
          max={words.length}
          min={0}
          onChange={setPosition}
          type="range"
          ref={position}
        />

        <h3>
          Open File:&nbsp;
          <input
            type="file"
            onChange={handleFileSelect}
          />
        </h3>
      </div>
    </div>
  )
}